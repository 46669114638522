import { useState } from 'react';
import { prefix } from '../../../../js/constants';
import Utils from '../../../../js/utils';
import { productsStub } from '../../../../products_remove';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import ScreenBestCombos from '../../common/best-combos/ScreenBestCombos';
import css from './ScreenTotalLookResults.module.scss';

const ScreenTotalLookResults = () => {
    const userPic = 'https://cdn-images.farfetch-contents.com/16/93/17/09/16931709_34526632_1000.jpg';
    const productsPerfect = [...productsStub].slice(0, 5);

    const categories = [
        { category: 'Низ', products: [...productsStub] },
        { category: 'Платья', products: [...productsStub] },
    ];

    const prices = ['Эконом', 'Средние', 'Премиум'];
    const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
    const onPriceChange = (price: string) => {
        Utils.selectItem(price, selectedPrices, setSelectedPrices);
    };

    return (
        <ScreenBestCombos pageTitle="Лучшие сочетания для твоей вещи" urlPath="total-look" />
        // <section className={css[prefix]}>
        //     <div className={css[prefix + '__user-picture-container']}>
        //         <div className={css[prefix + '__btn-back'] + ' ' + prefix + '__desktop'}>
        //             <ButtonBack titleDesktop="К вопросам" />
        //         </div>
        //         <header>
        //             <h2 className={prefix + '__page-title-left'}>Лучшие сочетания для твоей вещи</h2>
        //             <h4 className={prefix + '__subtitle'}>Выбирай готовые образы или заменяй отдельные вещи в них, чтобы посмотреть больше вариантов</h4>
        //         </header>
        //         <img className={css[prefix + '__user-picture']} src={userPic} alt="Загруженное фото" />
        //     </div>
        //     <section className={css[prefix + '__section-perfect']}>
        //         <h3 className={css[prefix + '__section-perfect-title']}>Идеально подходит</h3>
        //         <ul className={css[prefix + '__products-list']}>
        //             {productsPerfect.map((product) => (
        //                 <li key={product.id}>
        //                     <ProductCard
        //                         product={product}
        //                         urlPath="total-look"
        //                         customCardCss={css[prefix + '__product-item']}
        //                         customPicCss={css[prefix + '__product-item-pic']}
        //                     />
        //                 </li>
        //             ))}
        //         </ul>
        //     </section>
        //     <section className={css[prefix + '__section-more']}>
        //         <header className={css[prefix + '__section-more-header']}>
        //             <h3 className={css[prefix + '__section-more-title']}>Больше вариантов</h3>
        //             <div className={prefix + '__mobile' + ' ' + css[prefix + '__prices-dropdown']}>
        //                 <Dropdown options={prices.map((price, index) => ({ value: index, label: price }))} />
        //             </div>
        //             <div className={prefix + '__desktop'}>
        //                 <ul className={css[prefix + '__prices']}>
        //                     {prices.map((price, index) => {
        //                         const selected = selectedPrices.includes(price);
        //                         return (
        //                             <li
        //                                 key={index}
        //                                 className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
        //                                 onClick={() => onPriceChange(price)}
        //                             >
        //                                 {price}
        //                             </li>
        //                         );
        //                     })}
        //                 </ul>
        //             </div>
        //         </header>
        //         <div className={css[prefix + '__categories']}>
        //             {categories.map(({ category, products }, index) => (
        //                 <div key={index} className={css[prefix + '__category']}>
        //                     <h4 className={css[prefix + '__category-name']}>{category}</h4>
        //                     <ul className={css[prefix + '__products-list']}>
        //                         {products.map((product) => (
        //                             <li key={product.id}>
        //                                 <ProductCard
        //                                     product={product}
        //                                     urlPath="total-look"
        //                                     customCardCss={css[prefix + '__product-item']}
        //                                     customPicCss={css[prefix + '__product-item-pic']}
        //                                 ></ProductCard>
        //                             </li>
        //                         ))}
        //                     </ul>
        //                 </div>
        //             ))}
        //         </div>
        //     </section>
        // </section>
    );
};

export default ScreenTotalLookResults;
