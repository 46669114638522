import { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { prefix } from '../../../../js/constants';
import { QuestionType } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import { useLocation } from 'react-router-dom';
import css from './ScreenQuestions.module.scss';
import SexToggle from '../../../ui/sex-toggle/SexToggle';
import Utils from '../../../../js/utils';

const ScreenQuestions = ({
    pageTitle,
    pageSubtitle,
    buttonBackTitle = 'Назад',
    buttonNextTitle = 'Показать результаты',
    nextUrlPart = 'preferences/',
    options,
}: {
    pageTitle: JSX.Element | string;
    pageSubtitle?: string;
    buttonBackTitle?: string;
    buttonNextTitle?: string;
    nextUrlPart?: string;
    options: { [key: string]: boolean };
}) => {
    const categories = ['Верх', 'Верхняя одежда', 'Низ', 'Платья', 'Обувь', 'Аксессуары'];
    const ages = ['<18', '19-24', '25-35', '36-50', '51-65', '>65'];
    const sizesClothes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
    const sizesShoes = [35, 36, 37, 38, 39, 40];
    const prices = ['Эконом', 'Средние', 'Премиум'];
    const styles = ['Casual smart', 'Street style'];

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedAges, setSelectedAges] = useState<string[]>([]);
    const [selectedClothes, setSelectedClothes] = useState<string[]>([]);
    const [selectedShoes, setSelectedShoes] = useState<number[]>([]);
    const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
    const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
    const [selectedSex, setSelectedSex] = useState<number>(0);

    const match = useRouteMatch();
    const history = useHistory();
    const search = useLocation().search;
    const category = new URLSearchParams(search).get('category');
    const section = match.url.split('/')[1];

    const gotoNextScreen = () => {
        const categoryUrlPart = selectedCategories.length || category ? `?category=${selectedCategories[0] || category}` : '';
        history.push(`/${section}/${nextUrlPart}${categoryUrlPart}`);
    };

    const buttonNext = (
        <button
            className={prefix + '__button-main-black ' + css[prefix + '__btn-next']}
            type="button"
            disabled={
                // todo - sex ?
                (!selectedCategories.length && options[QuestionType.CATEGORIES]) ||
                (!selectedClothes.length && options[QuestionType.CLOTHES_SIZES]) ||
                (!selectedShoes.length && options[QuestionType.SHOES_SIZES]) ||
                (!selectedPrices.length && options[QuestionType.PRICES]) ||
                (!selectedStyles.length && options[QuestionType.STYLE])
            }
            onClick={gotoNextScreen}
        >
            {buttonNextTitle}
        </button>
    );

    return (
        <section className={prefix + '__layout'}>
            <div className={prefix + '__layout-title-overlay ' + prefix + '__layout-title-overlay--' + section}></div>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <h2 className={prefix + '__page-title-left ' + prefix + '__mobile'}>{pageTitle}</h2>

                <div className={css[prefix + '__options-block']}>
                    {!options[QuestionType.CATEGORIES] ? null : (
                        <div className={css[prefix + '__field']}>
                            <ul className={css[prefix + '__field-category-list']}>
                                {categories.map((cat, index) => {
                                    const selected = selectedCategories.includes(cat);
                                    return (
                                        <li
                                            className={
                                                css[prefix + '__field-category-item'] +
                                                (selected ? ' ' + css[prefix + '__field-category-item--selected'] : '') +
                                                ' ' +
                                                css[prefix + '__field-category-item--' + index]
                                            }
                                            key={index}
                                            onClick={() => Utils.selectItem(cat, selectedCategories, setSelectedCategories)}
                                        >
                                            {cat}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {!options[QuestionType.SEX] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Пол</div>
                            <div className={css[prefix + '__field-sex']}>
                                <SexToggle
                                    size="big"
                                    sexValue={selectedSex}
                                    onChange={(value: number) => {
                                        setSelectedSex(value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {!options[QuestionType.AGE] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Возраст</div>
                            <ul className={css[prefix + '__field-size-list'] + ' ' + css[prefix + '__field-size-list--age']}>
                                {ages.map((age, index) => {
                                    const selected = selectedAges.includes(age);
                                    return (
                                        <li
                                            className={
                                                css[prefix + '__field-size-item'] +
                                                ' ' +
                                                css[prefix + '__field-size-item--age'] +
                                                ' ' +
                                                (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                            }
                                            key={index}
                                            onClick={() => Utils.selectItem(age, selectedAges, setSelectedAges)}
                                        >
                                            {age}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {!options[QuestionType.CLOTHES_SIZES] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Размер одежды</div>
                            <ul className={css[prefix + '__field-size-list']}>
                                {sizesClothes.map((size, index) => {
                                    const selected = selectedClothes.includes(size);
                                    return (
                                        <li
                                            className={css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')}
                                            key={index}
                                            onClick={() => Utils.selectItem(size, selectedClothes, setSelectedClothes)}
                                        >
                                            {size}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {!options[QuestionType.SHOES_SIZES] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Размер обуви</div>
                            <ul className={css[prefix + '__field-size-list']}>
                                {sizesShoes.map((size, index) => {
                                    const selected = selectedShoes.includes(size);
                                    return (
                                        <li
                                            className={css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')}
                                            key={index + '_'}
                                            onClick={() => Utils.selectItem(size, selectedShoes, setSelectedShoes)}
                                        >
                                            {size}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {!options[QuestionType.PRICES] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Предпочтения по цене</div>
                            <ul className={css[prefix + '__field-size-list']}>
                                {prices.map((price, index) => {
                                    const selected = selectedPrices.includes(price);
                                    return (
                                        <li
                                            className={css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')}
                                            key={index + '_'}
                                            onClick={() => Utils.selectItem(price, selectedPrices, setSelectedPrices)}
                                        >
                                            {price}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    {!options[QuestionType.STYLE] ? null : (
                        <div className={css[prefix + '__field']}>
                            <div className={css[prefix + '__field-label']}>Стиль</div>
                            <ul className={css[prefix + '__field-size-list']}>
                                {styles.map((style, index) => {
                                    const selected = selectedStyles.includes(style);
                                    return (
                                        <li
                                            className={css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')}
                                            key={index + '_'}
                                            onClick={() => Utils.selectItem(style, selectedStyles, setSelectedStyles)}
                                        >
                                            {style}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                <div className={prefix + '__mobile ' + css[prefix + '__controls']}>{buttonNext}</div>
            </div>
            <div className={prefix + '__layout-right ' + css[prefix + '__layout-right']}>
                <header>
                    <ButtonBack titleDesktop={buttonBackTitle} />
                    <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                    <h4 className={prefix + '__subtitle'}>{pageSubtitle}</h4>
                </header>
                <div className={css[prefix + '__controls']}>{buttonNext}</div>
            </div>
        </section>
    );
};

export default ScreenQuestions;
