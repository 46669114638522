import { prefix } from '../../../js/constants';
import css from './SexToggle.module.scss';

const SexToggle = ({ size, sexValue, onChange }: { size: 'small' | 'big'; sexValue: number; onChange: Function }) => {
    const values = ['Женщина', 'Мужчина'];

    return (
        <div className={css[prefix + '__sex-toggle'] + ' ' + css[prefix + '__sex-toggle--' + size]}>
            {values.map((value, index) => (
                <label className={css[prefix + '__sex-toggle-label']} key={index}>
                    <input
                        className={css[prefix + '__sex-toggle-input']}
                        type="radio"
                        name="radio-group"
                        id="html5"
                        value={index}
                        radioGroup="sex"
                        checked={index === sexValue}
                        onChange={(e) => onChange(index)}
                    ></input>
                    <div className={css[prefix + '__sex-toggle-btn']}>{value}</div>
                </label>
            ))}
        </div>
    );
};

export default SexToggle;
