import { useHistory, useRouteMatch } from 'react-router';
import { prefix } from '../../../../js/constants';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import css from './ScreenPreferences.module.scss';

const ScreenPreferences = ({ pageTitle, pageSubtitle }: { pageTitle: JSX.Element; pageSubtitle: string }) => {
    const pic = 'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458464_1000.jpg';

    const match = useRouteMatch();
    const history = useHistory();
    const section = match.url.split('/')[1];

    const gotoNextScreen = () => {
        history.push(`/${section}/results`);
    };

    const buttonNext = (
        <button className={prefix + '__button-main-black ' + css[prefix + '__button-next']} type="button" onClick={gotoNextScreen}>
            Далее
        </button>
    );

    const buttonSelectAll = (
        <button className={prefix + '__button ' + css[prefix + '__button-select-all']} type="button">
            Выбрать все
        </button>
    );

    return (
        <section className={prefix + '__layout'}>
            <div className={prefix + '__layout-title-overlay ' + prefix + '__layout-title-overlay--' + section}></div>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <h2 className={prefix + '__page-title-left ' + prefix + '__mobile'}>{pageTitle}</h2>
                <div>
                    <img className={css[prefix + '__picture']} src={pic} alt="Загруженное фото" />
                    {/* <CheckboxPreferences label="Верхняя одежда"></CheckboxPreferences> */}
                </div>

                <div className={css[prefix + '__controls'] + ' ' + prefix + '__mobile'}>
                    {buttonNext}
                    {buttonSelectAll}
                </div>
            </div>

            <div className={prefix + '__layout-right ' + css[prefix + '__layout-right']}>
                <header>
                    <ButtonBack titleDesktop="К загрузке фото" />
                    <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                    <h4 className={prefix + '__subtitle'}>{pageSubtitle}</h4>
                </header>
                <div className={css[prefix + '__controls']}>
                    {buttonSelectAll}
                    {buttonNext}
                </div>
            </div>
        </section>
    );
};

export default ScreenPreferences;
