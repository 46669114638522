class Utils {
    public static selectItem<T>(value: T, selectedItems: Array<T>, setSelectedItems: Function) {
        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter((_value) => _value !== value));
        } else {
            setSelectedItems([...selectedItems, value]);
        }
    }
}

export default Utils;
