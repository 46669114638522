import { prefix } from '../../../../js/constants';
import Utils from '../../../../js/utils';
import { QuestionType } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import ButtonNext from '../../../ui/button-next/ButtonNext';
import ScreenQuestions from '../../common/questions/ScreenQuestions';
import css from './ScreenRecommendedPrefs.module.scss';

const ScreenRecommendedPrefs = () => {
    const pageTitle = (
        <>
            Что из&nbsp;этого тебя
            <br />
            интересует?
        </>
    );

    return (
        <ScreenQuestions
            pageTitle={pageTitle}
            pageSubtitle="Выберите одну или несколько категорий, в которых вы хотели бы получить рекомендации от киберстилиста."
            buttonNextTitle="Далее"
            nextUrlPart="questions"
            options={{ [QuestionType.CATEGORIES]: true, [QuestionType.PRICES]: true, [QuestionType.STYLE]: true }}
        />
    );
};

export default ScreenRecommendedPrefs;
