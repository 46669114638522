import { prefix } from '../../../js/constants';
import { ReactComponent as FacadeSvg } from '../../../assets/svg/facade-recommended.svg';
import { useHistory, useRouteMatch } from 'react-router';
import css from './ScreenRecommended.module.scss';

const ScreenRecommended = (props: any) => {
    const pageTitle = (
        <>
            Подберем только то,
            <br />
            что тебе действительно
            <br />
            понравится
        </>
    );

    const history = useHistory();
    const match = useRouteMatch();

    const gotoNextScreen = () => {
        history.push(`${match.url}/preferences`);
    };

    return (
        <section className={prefix + '__layout' + ' ' + css[prefix]}>
            <div className={prefix + '__gradients'}>
                <div className={prefix + '__red-wrapper'}>
                    <div className={prefix + '__red'}></div>
                </div>
                <div className={prefix + '__red2-wrapper'}>
                    <div className={prefix + '__red2'}></div>
                </div>
                <div className={prefix + '__blue-wrapper'}>
                    <div className={prefix + '__blue'}></div>
                </div>
                <div className={prefix + '__gradients-overlay'}></div>
            </div>

            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <header>
                    <h2 className={prefix + '__page-title-left ' + prefix + '__page-title-left--centered ' + css[prefix + '__page-title']}>{pageTitle}</h2>
                    <h4 className={prefix + '__subtitle ' + css[prefix + '__subtitle']}>
                        Ответь на несколько вопросов, чтобы искусственный интеллект понял, что тебе подойдет
                    </h4>
                    <div className={prefix + '__layout-facade-image'}>
                        <FacadeSvg />
                    </div>
                </header>
                <button className={prefix + '__button-main-black ' + prefix + '__desktop ' + css[prefix + '__btn']} onClick={gotoNextScreen}>
                    Начать
                </button>
                <button className={prefix + '__button-main ' + prefix + '__mobile ' + css[prefix + '__btn']} onClick={gotoNextScreen}>
                    Начать
                </button>
            </div>
            <div className={prefix + '__layout-right'}>
                <div className={prefix + '__layout-facade-image-right'}>
                    <FacadeSvg />
                </div>
            </div>
        </section>
    );
};

export default ScreenRecommended;
