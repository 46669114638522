import { prefix } from '../../../js/constants';
import { ReactComponent as FacadeTotalLookSvg } from '../../../assets/svg/facade-total-look.svg';
import { ReactComponent as FacadeTotalLookDesktopSvg } from '../../../assets/svg/facade-total-look-desktop.svg';
import classes from './ScreenTotalLook.module.scss';
import ScreenFileUpload from '../common/upload-file/ScreenFileUpload';

const ScreenTotalLook = (props: any) => {
    const pageTitle = (
        <>
            Узнай, с&nbsp;чем можно
            <br />
            сочетать любимую
            <br />
            вещь
        </>
    );
    return <ScreenFileUpload pageTitle={pageTitle} css={classes} facadeSvg={<FacadeTotalLookSvg />} facadeDesktopSvg={<FacadeTotalLookDesktopSvg />} />;
};

export default ScreenTotalLook;
