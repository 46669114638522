import { prefix } from '../../../../js/constants';
import { productsStub } from '../../../../products_remove';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import css from './ScreenBestCombos.module.scss';

const ScreenBestCombos = ({ pageTitle, urlPath, mode = 'cols-2' }: { pageTitle: string; urlPath: string; mode?: 'cols-2' | 'cols-3' }) => {
    const products = [...productsStub];
    const product = products[0];

    const linkBuyLook = (
        <a className={prefix + '__button-main-black ' + css[prefix + '__btn-buy']} href="#">
            Купить весь образ
        </a>
    );

    const btnPrev = (
        <button className={prefix + '__button-main ' + css[prefix + '__controls-btn'] + ' ' + css[prefix + '__controls-btn--prev']} type="button">
            Предыдущий
        </button>
    );

    const btnNext = (
        <button className={prefix + '__button-main ' + css[prefix + '__controls-btn'] + ' ' + css[prefix + '__controls-btn--next']} type="button">
            Следующий
        </button>
    );

    return (
        <section className={prefix + '__layout ' + css[prefix] + ' ' + css[prefix + '--' + mode]}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <div className={prefix + '__mobile'}>
                    <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                    <div className={css[prefix + '__user-product']}>
                        <ProductCard product={product} hasFullView={true} hasLike={true} urlPath={urlPath} />
                        <div className={css[prefix + '__user-product-options']}>
                            <p className={css[prefix + '__user-product-text']}>
                                Выбирай готовые образы или заменяй отдельные вещи в&nbsp;них, чтобы посмотреть больше вариантов
                            </p>
                            <div className={css[prefix + '__prices']}>
                                <Dropdown
                                    options={[
                                        { value: 0, label: 'Эконом' },
                                        { value: 1, label: 'Средние цены' },
                                        { value: 1, label: 'Премиум' },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ul className={css[prefix + '__products']}>
                    {products.map((product) => (
                        <li key={product.id} className={css[prefix + '__product']}>
                            <ProductCard
                                product={product}
                                hasFullView={true}
                                hasRefresh={true}
                                hasLike={true}
                                urlPath={urlPath}
                                customCardCss={css[prefix + '__product']}
                                customPicCss={css[prefix + '__product-picture']}
                            />
                        </li>
                    ))}
                </ul>
                <div className={prefix + '__mobile'}>{linkBuyLook}</div>
                <div className={prefix + '__mobile ' + css[prefix + '__sticky-controls']}>
                    {btnPrev}
                    {btnNext}
                </div>
            </div>
            <div className={prefix + '__layout-right ' + css[prefix + '__layout-right']}>
                <div>
                    <ButtonBack titleDesktop="Назад" />
                    <div className={css[prefix + '__main-block']}>
                        <header>
                            <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                            <h4 className={prefix + '__subtitle'}>
                                Выбирай готовые образы или заменяй отдельные вещи в них, чтобы посмотреть больше вариантов
                            </h4>
                        </header>
                        <div className={css[prefix + '__main-block-card-wrapper']}>
                            <ProductCard
                                product={productsStub[0]}
                                urlPath={urlPath}
                                customCardCss={css[prefix + '__main-block-card']}
                                customPicCss={css[prefix + '__main-block-card-pic']}
                            />
                        </div>
                    </div>
                </div>
                <div className={css[prefix + '__controls']}>
                    {linkBuyLook}
                    <div className={css[prefix + '__controls-next-prev']}>
                        {btnPrev}
                        {btnNext}
                    </div>
                    {/* <p>Поделиться образом</p> */}
                </div>
            </div>
        </section>
    );
};

export default ScreenBestCombos;
