import React, { ReactElement, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import ErrorPresenter from '../../../ui/error-presenter/ErrorPresenter';
import _css from './ScreenFileUpload.module.scss';

const ScreenFileUpload = ({
    pageTitle,
    facadeSvg,
    facadeDesktopSvg,
    css,
}: {
    pageTitle: JSX.Element;
    facadeSvg: ReactElement;
    facadeDesktopSvg: ReactElement;
    css: {
        readonly [key: string]: string;
    };
}) => {
    const prefix = 'garderobo';
    const [linkValue, setLinkValue] = useState<string>('');
    const [fileValue, setFileValue] = useState<File | null>(null);
    const [error, setError] = useState<string>('');
    const fileInput: any = React.createRef(); // todo type
    const match = useRouteMatch();
    const history = useHistory();

    const searchByImage = () => {
        console.log('search...');
        history.push(`${match.url}/questions`);
    };

    return (
        <section className={prefix + '__layout' + ' ' + css[prefix]}>
            <div className={prefix + '__gradients'}>
                <div className={prefix + '__red-wrapper'}>
                    <div className={prefix + '__red'}></div>
                </div>
                <div className={prefix + '__red2-wrapper'}>
                    <div className={prefix + '__red2'}></div>
                </div>
                <div className={prefix + '__blue-wrapper'}>
                    <div className={prefix + '__blue'}></div>
                </div>
                <div className={prefix + '__gradients-overlay'}></div>
            </div>

            <div className={prefix + '__layout-center'}>
                <div className={_css[prefix + '__content']}>
                    <h2 className={prefix + '__page-title-left ' + prefix + '__page-title-left--centered ' + _css[prefix + '__page-title']}>{pageTitle}</h2>
                    <div className={prefix + '__layout-facade-image ' + _css[prefix + '__facade-image']}>{facadeSvg}</div>
                    {!fileValue ? (
                        <input
                            id="file"
                            className={_css['custom-file-input']}
                            aria-label="File browser example"
                            type="file"
                            ref={fileInput}
                            onChange={() => {
                                searchByImage();
                                //setFileValue(fileInput?.current?.files[0]);
                            }}
                        />
                    ) : (
                        <div className={_css[prefix + '__file-value']}>
                            <div className={_css[prefix + '__file-image']} style={{ backgroundImage: `url(${URL.createObjectURL(fileValue)})` }}></div>
                            <span className={_css[prefix + '__file-value-text']}>{fileValue?.name}</span>
                            <button
                                className={prefix + '__button-close'}
                                type="button"
                                onClick={() => {
                                    searchByImage();
                                    //setFileValue(null);
                                }}
                            ></button>
                        </div>
                    )}
                    <div className={_css[prefix + '__or-text']}>или</div>
                    <input
                        className={
                            prefix + '__input-text ' + _css[prefix + '__input-text-link'] + (linkValue ? ' ' + _css[prefix + '__input-text-link--valued'] : '')
                        }
                        type="text"
                        placeholder="Вставить ссылку на фото"
                        value={linkValue}
                        onChange={(e) => setLinkValue(e.target.value)}
                    />
                    <label htmlFor="inputLinkToImg"></label>
                    <div className={_css[prefix + '__errors']}>
                        <ErrorPresenter
                            text="Вставьте ссылку на конкретное изображение"
                            tooltipText="Сделайте длинный тап по изображению и выберите действие “копировать изображение” или “скопировать”."
                        ></ErrorPresenter>
                    </div>
                    <button className={_css[prefix + '__button-find']} type="button" disabled={!(linkValue || fileValue)} onClick={() => searchByImage()}>
                        Найти
                    </button>
                </div>
            </div>
            <div className={prefix + '__layout-right'}>
                <div className={prefix + '__layout-facade-image-right'}>{facadeDesktopSvg}</div>
            </div>
        </section>
    );
};

export default ScreenFileUpload;
