import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { prefix } from '../../../js/constants';
import Actions from '../../../js/actions';
import IProduct from '../../../models/product';
import { productsStub } from '../../../products_remove';
import ButtonBack from '../button-back/ButtonBack';
import css from './ProductPage.module.scss';

const ProductPage = () => {
    const { productId } = useParams<{ productId: string }>();
    const product: IProduct = productsStub[+productId]; // todo - load from back or from prev page ?

    const [isRobotOpened, setIsRobotOpened] = useState(false);
    const [currentPic, setCurrentPic] = useState(product.pic);
    const [isFullView, setIsFullView] = useState(false);

    const btnBuy = (
        <a href={product.link} className={prefix + '__button-main-black' + ' ' + css[prefix + '__button-buy']} target="_blank">
            Купить
        </a>
    );

    const picContainer = (
        <div className={css[prefix + '__picture-container']}>
            <button className={prefix + '__button-like'} type="button" onClick={() => Actions.like('todo')}></button>
            <button
                className={css[prefix + '__picture']}
                type="button"
                style={{ backgroundImage: `url(${currentPic})` }}
                onClick={() => setIsFullView(true)}
            ></button>
        </div>
    );

    const thumbnails = (
        <div className={css[prefix + '__thumbnails']}>
            {product.pics?.map((picUrl, index) => (
                <button key={index} className={css[prefix + '__btn-thumbnail']} type="button" onClick={() => setCurrentPic(picUrl)}>
                    <div className={css[prefix + '__btn-thumbnail-pic']} style={{ backgroundImage: `url(${picUrl})` }}></div>
                </button>
            ))}
        </div>
    );

    const history = useHistory();
    return (
        <div className={css[prefix]}>
            {!product ? (
                'Продукт не найден'
            ) : (
                <section className={prefix + '__layout' + ' ' + css[prefix + '__layout'] + (isFullView ? ' ' + css[prefix + '__layout--overlayed'] : '')}>
                    {!isFullView ? null : (
                        <div className={css[prefix + '__overlay']}>
                            <button
                                className={prefix + '__button-close' + ' ' + css[prefix + '__btn-close']}
                                type="button"
                                onClick={() => setIsFullView(false)}
                            ></button>
                            {thumbnails}
                            <img className={css[prefix + '__overlay-pic']} src={currentPic} alt={product.name} />
                        </div>
                    )}

                    <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center'] + ' ' + prefix + '__desktop'}>
                        <div className={css[prefix + '__btn-back']}>
                            <ButtonBack titleDesktop="К результатам" />
                        </div>
                        <div className={css[prefix + '__pictures']}>
                            <div className={css[prefix + '__thumbnails']}>{thumbnails}</div>
                            {picContainer}
                        </div>
                    </div>
                    <div className={prefix + '__layout-right' + ' ' + css[prefix + '__layout-right'] + ' ' + prefix + '__desktop'}>
                        <div className={css[prefix + '__layout-right-content']}>
                            <div className={css[prefix + '__titles']}>
                                <div className={css[prefix + '__brand']}>{product.brand}</div>
                                <div className={css[prefix + '__name']}>{product.name}</div>
                            </div>
                            <div className={css[prefix + '__buy-controls']}>
                                <div className={css[prefix + '__price']}>{product.price}</div>
                                {btnBuy}
                                <div className={css[prefix + '__sizes-title']}>Размеры в наличии в магазине</div>
                                <ul className={css[prefix + '__sizes-list']}>
                                    {product.sizes?.map((size, index) => (
                                        <li key={index} className={prefix + '__select-box-item'}>
                                            {size}
                                        </li>
                                    ))}
                                </ul>
                                <a className={prefix + '__link-gray'} href={product.link} target="_blank">
                                    Подробнее о товаре
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className={prefix + '__mobile'}>
                        <div className={css[prefix + '__content']}>
                            {picContainer}
                            <div className={css[prefix + '__details']}>
                                <div className={css[prefix + '__brand']}>{product.brand}</div>
                                <div className={css[prefix + '__price']}>{product.price}</div>
                            </div>
                            {btnBuy}
                        </div>
                    </div>

                    <div
                        className={css[prefix + '__robot-block'] + (isRobotOpened ? ' ' + css[prefix + '__robot-block--opened'] : '')}
                        onMouseOver={() => setIsRobotOpened(true)}
                        onMouseOut={() => setIsRobotOpened(false)}
                    >
                        <h3 className={css[prefix + '__robot-title']}>Робот стилист знает с&nbsp;чем это лучше носить</h3>
                        <button className={prefix + '__button-main'} type="button" onClick={() => history.push('/similar/best-combos')}>
                            Попробовать бесплатно
                        </button>
                    </div>
                </section>
            )}
        </div>
    );
};

export default ProductPage;
