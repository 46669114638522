import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { prefix } from '../../../../js/constants';
import IProduct from '../../../../models/product';
import { productsStub } from '../../../../products_remove';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import css from './ScreenRecommendedLikes.module.scss';

const ScreenRecommendedLikes = () => {
    const [products, setProducts] = useState<IProduct[]>([...productsStub].slice(0, 5));
    const [dislikesVisible, setDislikesVisible] = useState<Array<boolean>>(products.map(() => false));

    const search = useLocation().search;
    const category = new URLSearchParams(search).get('category');

    const history = useHistory();
    const match = useRouteMatch();
    const section = match.url.split('/')[1];

    const gotoNextProduct = (isLike: boolean) => {
        if (products.length) {
            const productsNew = [...products];
            const first = productsNew.shift();
            setProducts(productsNew);
        }
    };

    const gotoNextProductDesktop = (isLike: boolean) => {
        // if (products.length) {
        //     const productsNew = [...products];
        //     const first = productsNew.shift();
        //     setProducts(productsNew);
        // }
    };

    /** в 1й версии не делаем */
    const setDislike = (index: number, isVisible: boolean) => {
        // const dislikesNew = [...dislikesVisible];
        // dislikesNew[index] = isVisible;
        // setDislikesVisible(dislikesNew);
    };

    useEffect(() => {
        if (!products.length) {
            history.push('/recommended/results/');
        }
    }, [products]);

    return (
        <>
            {!dislikesVisible[0] ? null : (
                <>
                    <div className={prefix + '__mobile' + ' ' + css[prefix + '__overlay-dark']}></div>
                    <div className={css[prefix + '__dislikes']}>
                        <button
                            className={prefix + '__button-close' + ' ' + css[prefix + '__button-close']}
                            type="button"
                            onClick={() => setDislike(0, false)}
                        ></button>
                        <div className={css[prefix + '__dislikes-content']}>
                            <h4 className={css[prefix + '__dislikes-title']}>Что именно Вам не понравилось?</h4>
                            <div className={css[prefix + '__dislikes-controls']}>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--price'} type="button">
                                    Цена
                                </button>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--color'} type="button">
                                    Цвет
                                </button>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--style'} type="button">
                                    Стиль
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <section className={prefix + '__layout'}>
                <div className={prefix + '__layout-title-overlay' + ' ' + prefix + '__layout-title-overlay--' + section}></div>
                <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center']}>
                    <div className={css[prefix + '__products']}>
                        {products.map((product, index) => (
                            <article className={css[prefix + '__product'] + ' ' + prefix + '__mobile'} key={product.id}>
                                <div className={css[prefix + '__picture']} style={{ backgroundImage: `url(${product.pic})` }}></div>
                                <div className={css[prefix + '__details']}>
                                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                                    <div className={css[prefix + '__price']}>{product.price}</div>
                                </div>
                                <div className={css[prefix + '__question'] + ' ' + prefix + '__mobile'}>Вам нравится эта вещь?</div>
                                <div className={css[prefix + '__controls']}>
                                    <button
                                        className={prefix + '__button-no' + ' ' + css[prefix + '__controls-btn']}
                                        type="button"
                                        onClick={() => gotoNextProduct(false)}
                                    >
                                        <span className={prefix + '__mobile'}>Нет</span>
                                    </button>
                                    <button
                                        className={prefix + '__button-yes' + ' ' + css[prefix + '__controls-btn']}
                                        type="button"
                                        onClick={() => gotoNextProduct(true)}
                                    >
                                        <span className={prefix + '__mobile'}>Да</span>
                                    </button>
                                </div>
                                {/* {!dislikesVisible[index] ? null : (
                                    <div className={prefix + '__desktop' + ' ' + css[prefix + '__dislikes-desktop']}>
                                        <button
                                            className={prefix + '__button-close' + ' ' + css[prefix + '__button-close']}
                                            type="button"
                                            onClick={() => setDislike(index, false)}
                                        ></button>
                                        <h4 className={css[prefix + '__dislikes-title']}>Что Вам не понравилось?</h4>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--price'} type="button">
                                            Цена
                                        </button>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--color'} type="button">
                                            Цвет
                                        </button>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--style'} type="button">
                                            Стиль
                                        </button>
                                    </div>
                                )} */}
                            </article>
                        ))}

                        {products.map((product, index) => (
                            <article className={css[prefix + '__product'] + ' ' + prefix + '__desktop'} key={product.id}>
                                <div className={css[prefix + '__picture']} style={{ backgroundImage: `url(${product.pic})` }}></div>
                                <div className={css[prefix + '__controls']}>
                                    <button
                                        className={
                                            prefix +
                                            '__button-no' +
                                            ' ' +
                                            css[prefix + '__controls-btn'] +
                                            (index % 3 ? ' ' + prefix + '__button-no--selected' : '')
                                        }
                                        type="button"
                                        onClick={() => gotoNextProductDesktop(false)}
                                    >
                                        <span className={prefix + '__mobile'}>Нет</span>
                                    </button>
                                    <button
                                        className={
                                            prefix +
                                            '__button-yes' +
                                            ' ' +
                                            css[prefix + '__controls-btn'] +
                                            (index % 2 ? ' ' + prefix + '__button-yes--selected' : '')
                                        }
                                        type="button"
                                        onClick={() => gotoNextProductDesktop(true)}
                                    >
                                        <span className={prefix + '__mobile'}>Да</span>
                                    </button>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>

                <div className={prefix + '__layout-right' + ' ' + css[prefix + '__layout-right']}>
                    <header>
                        <ButtonBack titleDesktop="Назад" />
                        <h2 className={prefix + '__page-title-left'}>
                            Выберите,
                            <br />
                            что понравилось
                        </h2>
                        <h4 className={prefix + '__subtitle' + ' ' + css[prefix + '__subtitle']}>
                            Выбирай готовые образы или заменяй отдельные вещи в них, чтобы посмотреть больше вариантов.{' '}
                        </h4>
                        {category ? (
                            <div className={prefix + '__subtitle' + ' ' + css[prefix + '__category'] + ' ' + css[prefix + '__category--0']}>{category}</div>
                        ) : null}
                    </header>
                </div>
            </section>
        </>
    );
};

export default ScreenRecommendedLikes;
