import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { prefix } from '../../../../js/constants';
import Utils from '../../../../js/utils';
import IProduct from '../../../../models/product';
import { productsStub } from '../../../../products_remove';
import Dropdown from '../../../ui/dropdown/Dropdown';
import SexToggle from '../../../ui/sex-toggle/SexToggle';
import css from './ScreenPopularContent.module.scss';

const ScreenPopularContent = ({ productCardType = 'look', children }: { productCardType?: 'product' | 'look'; children?: any }) => {
    const partSize = 8;
    const initialProducts = [...productsStub];
    const prices = ['Эконом', 'Средние', 'Премиум'];
    const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
    const [productsPartCount, setProductsPartCount] = useState<number>(partSize);
    const [allProducts, setAllProducts] = useState<Array<IProduct>>(initialProducts);
    const [sex, setSex] = useState<number>(0); // todo take from localStorage
    const [looks, setLooks] = useState(allProducts.filter((product) => product.sex === 7).slice(0, productsPartCount));

    const match = useRouteMatch();

    const showMore = () => {
        const newPartCount = productsPartCount + partSize;
        setProductsPartCount(newPartCount);
        if (allProducts.length >= newPartCount) {
            setLooks(allProducts.slice(0, newPartCount));
        } else {
            setLooks(allProducts);
        }
    };

    const onSexChange = (value: number) => {
        setAllProducts(initialProducts.filter((product) => value === product.sex));
        setSex(value);
        setProductsPartCount(partSize);
    };

    const onPriceChange = (price: string) => {
        Utils.selectItem(price, selectedPrices, setSelectedPrices);
        setAllProducts(initialProducts.filter((product) => product.price > 100000));
        setProductsPartCount(partSize);
    };

    const onStyleSelect = (styleValue: number) => {
        // todo - filter by style
    };

    useEffect(() => {
        setLooks(allProducts.filter((product) => product.sex === 0).slice(0, productsPartCount));
    }, [allProducts]);

    return (
        <>
            <div className={css[prefix + '__options']}>
                <div className={css[prefix + '__options-prices-sex']}>
                    <ul className={css[prefix + '__prices']}>
                        {prices.map((price, index) => {
                            const selected = selectedPrices.includes(price);
                            return (
                                <li
                                    key={index}
                                    className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
                                    onClick={() => onPriceChange(price)}
                                >
                                    {price}
                                </li>
                            );
                        })}
                    </ul>
                    <div>
                        <SexToggle size="small" sexValue={sex} onChange={onSexChange} />
                    </div>
                </div>
                <Dropdown
                    options={[
                        { value: 0, label: 'Smart casual' },
                        { value: 1, label: 'Street style' },
                    ]}
                    onSelect={onStyleSelect}
                />
            </div>

            {children || (
                <ul className={css[prefix + '__looks']}>
                    {looks.map((look) => (
                        <li key={look.id} className={css[prefix + '__look']}>
                            <NavLink to={`${match.url}/${look.id}`}>
                                <div className={css[prefix + '__look-picture']} style={{ backgroundImage: `url(${look.pic}` }}></div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
            {allProducts.length <= productsPartCount ? null : (
                <div className={css[prefix + '__controls']}>
                    <button className={prefix + '__button-main-black' + ' ' + css[prefix + '__btn']} type="button" onClick={showMore}>
                        Показать еще ({allProducts.length - productsPartCount})
                    </button>
                </div>
            )}
        </>
    );
};

export default ScreenPopularContent;
