import React, { useEffect, useState } from 'react';
import { prefix } from '../../../js/constants';
import css from './ErrorPresenter.module.scss';

const ErrorPresenter = ({ text, tooltipText }: { text: string; tooltipText?: string }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    const closeError = () => {
        setIsTooltipVisible(false);
    };

    const toggleError = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsTooltipVisible(!isTooltipVisible);
    };

    useEffect(() => {
        document.body.addEventListener('click', closeError);

        return function cleanup() {
            window.removeEventListener('click', closeError);
        };
    }, []);

    return (
        <div className={css[prefix]}>
            {!isTooltipVisible ? null : <div className={css[prefix + '__overlay']}></div>}
            <span className={css[prefix + '__text']}>{text}</span>
            <span className={css[prefix + '__question']} onClick={(e) => toggleError(e)}>
                ?
            </span>
            <div className={css[prefix + '__tooltip'] + (isTooltipVisible ? ' ' + css[prefix + '__tooltip--opened'] : '')}>{tooltipText}</div>
        </div>
    );
};

export default ErrorPresenter;
