import { useHistory } from 'react-router';
import { prefix } from '../../../js/constants';
import css from './ButtonBack.module.scss';

const ButtonBack = ({ titleMobile, titleDesktop, url }: { titleMobile?: string; titleDesktop?: string; url?: string }) => {
    console.log(titleMobile, titleDesktop);
    const history = useHistory();

    return (
        <button
            className={css[prefix]}
            type="button"
            onClick={
                url
                    ? () => {
                          history.push(url);
                      }
                    : history.goBack
            }
        >
            {!(titleMobile || titleDesktop) ? null : (
                <>
                    <span className={prefix + '__mobile'}>{titleMobile}</span>
                    <span className={prefix + '__desktop'}>{titleDesktop}</span>
                </>
            )}
        </button>
    );
};

export default ButtonBack;
