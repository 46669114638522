import { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { prefix } from '../../../../js/constants';
import Utils from '../../../../js/utils';
import { productsStub } from '../../../../products_remove';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import css from './ScreenRecommendedResults.module.scss';

const ScreenRecommendedResults = () => {
    const products = [...productsStub, ...productsStub, ...productsStub, ...productsStub];
    console.log(products);

    const prices = ['Верхняя одежда', 'Низ', 'Верх', 'Обувь', 'Аксессуары'];
    const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
    const [looks, setLooks] = useState(products.slice(0, 8));

    const match = useRouteMatch();

    const showMore = () => {
        setLooks([...looks, ...productsStub]);
    };

    return (
        <section className={prefix + '__layout' + ' ' + css[prefix]}>
            <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center']}>
                <div className={css[prefix + '__btn-back'] + ' ' + prefix + '__desktop'}>
                    <ButtonBack titleDesktop="К вопросам" />
                </div>

                <h2 className={prefix + '__page-title-left' + ' ' + css[prefix + '__page-title-left']}>Рекомендации киберстилиста только для тебя</h2>
                <div className={css[prefix + '__options']}>
                    <ul className={css[prefix + '__prices']}>
                        {prices.map((price, index) => {
                            const selected = selectedPrices.includes(price);
                            return (
                                <li
                                    key={index}
                                    className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
                                    onClick={() => Utils.selectItem(price, selectedPrices, setSelectedPrices)}
                                >
                                    {price}
                                </li>
                            );
                        })}
                    </ul>

                    <div className={css[prefix + '__prices-dropdown']}>
                        <Dropdown
                            options={[
                                { value: 0, label: 'Эконом' },
                                { value: 1, label: 'Средние цены' },
                                { value: 2, label: 'Премиум' },
                            ]}
                        />
                    </div>
                </div>
                <ul className={css[prefix + '__looks']}>
                    {looks.map((look) => (
                        <li key={look.id} className={css[prefix + '__look']}>
                            <NavLink to={`${match.url}/${look.id}`}>
                                <ProductCard
                                    product={look}
                                    urlPath="recommended"
                                    customCardCss={css[prefix + '__product-card']}
                                    customPicCss={css[prefix + '__product-pic']}
                                />
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div className={css[prefix + '__controls']}>
                    <button className={prefix + '__button-main-black' + ' ' + css[prefix + '__btn']} type="button" onClick={showMore}>
                        Показать еще (56)
                    </button>
                </div>
            </div>
        </section>
    );
};

export default ScreenRecommendedResults;
