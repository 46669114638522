import { useHistory, useRouteMatch } from 'react-router';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LStorage, prefix } from '../../../js/constants';
import IProduct from '../../../models/product';
import css from './ProductCard.module.scss';
import Actions from '../../../js/actions';

const ProductCard = ({
    product,
    hasFullView = false,
    hasLike = false,
    hasRefresh = false,
    customCardCss,
    customPicCss,
    urlPath,
    onLike,
}: {
    product: IProduct;
    hasFullView?: boolean;
    hasLike?: boolean;
    hasRefresh?: boolean;
    customCardCss?: string;
    customPicCss?: string;
    urlPath: string;
    onLike?: Function;
}) => {
    const match = useRouteMatch();

    const [isFullViewOpened, setIsFullViewOpened] = useState(false);

    const likedProductIdsStorage = localStorage.getItem(LStorage.likedProductIds);
    let likedProductIds = likedProductIdsStorage ? JSON.parse(likedProductIdsStorage) : [];
    const [isLiked, setIsLiked] = useState(likedProductIds?.indexOf(product.id) !== -1);
    const [currentPic, setCurrentPic] = useState(product.pic);

    const picComponent = (
        <div
            className={css[prefix + '__picture'] + (customPicCss ? ' ' + customPicCss : '')}
            style={{ backgroundImage: `url(${product.pic})` }}
            onClick={hasFullView ? () => setIsFullViewOpened(true) : undefined}
        ></div>
    );

    const thumbnails = (
        <div className={css[prefix + '__thumbnails']}>
            {product.pics?.map((picUrl, index) => (
                <button key={index} className={css[prefix + '__btn-thumbnail']} type="button" onClick={() => setCurrentPic(picUrl)}>
                    <div className={css[prefix + '__btn-thumbnail-pic']} style={{ backgroundImage: `url(${picUrl})` }}></div>
                </button>
            ))}
        </div>
    );

    const doRefresh = () => {
        //todo
    };

    const toggleLike = () => {
        // Actions.like(product.id)
        const likedProductIdsStorage = localStorage.getItem(LStorage.likedProductIds);
        const productId = product.id;
        if (likedProductIdsStorage) {
            let likedProductIds: Array<number> = JSON.parse(likedProductIdsStorage);
            const newProductIds = likedProductIds.includes(productId)
                ? likedProductIds.filter((_productId) => _productId !== productId)
                : [...likedProductIds, productId];
            localStorage.setItem(LStorage.likedProductIds, JSON.stringify(newProductIds));
        } else {
            localStorage.setItem(LStorage.likedProductIds, JSON.stringify([productId]));
        }
        const newLikedValue = !isLiked;
        setIsLiked(newLikedValue);
        if (onLike) {
            onLike(product.id, newLikedValue);
        }
    };

    return (
        <article className={css[prefix] + (customCardCss ? ' ' + customCardCss : '')}>
            <div className={css[prefix + '__picture-container']}>
                {hasRefresh ? <button className={prefix + '__button-refresh'} onClick={doRefresh}></button> : null}
                {hasLike ? (
                    <button className={prefix + '__button-like' + (isLiked ? ' ' + prefix + '__button-like--liked' : '')} onClick={toggleLike}></button>
                ) : null}
                {hasFullView ? picComponent : <NavLink to={`/${urlPath}/products/${product.id}`}>{picComponent}</NavLink>}
            </div>
            <div className={css[prefix + '__details']}>
                <div className={css[prefix + '__details-info']}>
                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                    <div className={css[prefix + '__price']}>{product.price}</div>
                </div>
                <a className={prefix + '__button-small'} href={product.link} target="_blank">
                    Купить
                </a>
            </div>
            {!isFullViewOpened ? null : (
                <div className={css[prefix + '__overlay']}>
                    <button
                        className={prefix + '__button-close' + ' ' + css[prefix + '__overlay-button-close']}
                        type="button"
                        onClick={() => setIsFullViewOpened(false)}
                    ></button>
                    {thumbnails}
                    <img className={css[prefix + '__overlay-pic']} src={product.pic} alt={product.name} />
                </div>
            )}
        </article>
    );
};

export default ProductCard;
