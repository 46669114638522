import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { prefix } from '../../../js/constants';
import { productsStub } from '../../../products_remove';
import ScreenPopularContent from './popular-content/ScreenPopularContent';
import css from './ScreenPopular.module.scss';

const ScreenPopular = (props: any) => {
    const prices = ['Эконом', 'Средние', 'Премиум'];
    const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
    const [looks, setLooks] = useState(productsStub);

    const match = useRouteMatch();

    const showMore = () => {
        setLooks([...looks, ...productsStub]);
    };

    return (
        <section className={prefix + '__layout' + ' ' + css[prefix]}>
            <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center']}>
                <h2 className={prefix + '__page-title-left' + ' ' + css[prefix + '__page-title-left']}>Узнай что сейчас в тренде</h2>
                <ScreenPopularContent />
            </div>
        </section>
    );
};

export default ScreenPopular;
