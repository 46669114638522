import React, { useEffect, useState } from 'react';
import { prefix } from '../../../js/constants';
import css from './Dropdown.module.scss';

const Dropdown = ({ options, onSelect }: { options: Array<{ value: number; label: string }>; onSelect?: Function }) => {
    const selectedValue = options[0].value;
    const [selected, setSelected] = useState(selectedValue);
    const [opened, setOpened] = useState(false);

    const onSelectItem = (value: number) => {
        setSelected(value);
        setOpened(false);
        if (onSelect) {
            onSelect(value);
        }
    };

    const showList = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpened(!opened);
    };

    const closeDropdown = () => {
        setOpened(false);
    };

    useEffect(() => {
        document.body.addEventListener('click', closeDropdown);

        return function cleanup() {
            window.removeEventListener('click', closeDropdown);
        };
    }, []);

    return (
        <div className={css[prefix] + (opened ? ' ' + css[prefix + '--opened'] : '')}>
            <div className={css[prefix + '__value']} onClick={(e) => showList(e)}>
                {options.find((el) => el.value === selected)?.label}
            </div>
            <ul className={css[prefix + '__list'] + (opened ? ' ' + css[prefix + '__list--opened'] : '')}>
                {options.map(({ value, label }) => (
                    <li
                        key={value}
                        data-value={value}
                        className={css[prefix + '__list-item'] + (selected === value ? ' ' + css[prefix + '__list-item--selected'] : '')}
                        onClick={() => onSelectItem(value)}
                    >
                        {label}
                    </li>
                ))}
            </ul>
        </div>
        // <select className={css[prefix]}>
        //     {options.map(({ value, label }) => (
        //         <option value={value}>
        //             {label}
        //             <div>test</div>
        //         </option>
        //     ))}
        // </select>
    );
};

export default Dropdown;
