import { PropsWithChildren } from 'react';
import ScreenFavorites from './components/screens/favorites/ScreenFavorites';
import ScreenPopular from './components/screens/popular/ScreenPopular';
import ScreenRecommended from './components/screens/recommended/ScreenRecommended';

import ScreenSimilar from './components/screens/similar/ScreenSimilar';
import ScreenTotalLook from './components/screens/total-look/ScreenTotalLook';

export enum LinkEnum {
    HOME = '/',
    SIMILAR = '/similar',
    TOTAL_LOOK = '/total-look',
    RECOMMENDED = '/recommended',
    POPULAR = '/popular',
    FAVORITES = '/favorites',
}

interface IMainMenu {
    name: string;
    title: string;
    link: LinkEnum;
    facadeSvg: string | null;
    component: (props: PropsWithChildren<any>) => JSX.Element;
}

export const MAIN_MENU: Array<IMainMenu> = [
    {
        name: 'similar',
        title: 'Найти вещь по фото',
        link: LinkEnum.SIMILAR,
        facadeSvg: 'similar.svg',
        component: () => <ScreenSimilar />,
    },
    {
        name: 'total-look',
        title: 'Подобрать образ',
        link: LinkEnum.TOTAL_LOOK,
        facadeSvg: 'facade-total-look.svg',
        component: () => <ScreenTotalLook />,
    },
    {
        name: 'recommended',
        title: 'Рекомендации',
        link: LinkEnum.RECOMMENDED,
        facadeSvg: 'facade-total-look.svg',
        component: () => <ScreenRecommended />,
    },
    {
        name: 'popular',
        title: 'Что в тренде',
        link: LinkEnum.POPULAR,
        facadeSvg: 'facade-total-look.svg',
        component: () => <ScreenPopular />,
    },
    {
        name: 'favorites',
        title: 'Избранное',
        link: LinkEnum.FAVORITES,
        facadeSvg: null,
        component: () => <ScreenFavorites />,
    },
];

// export const SECOND_ROUTES: Array<IGeneralMenu> = [
//   {
//     title: 'Pokemon',
//     link: LinkEnum.POKEMON,
//     component: ({ id }: PokemonProps) => <PokemonPage id={id} />,
//   },
// ];

// interface IAccMenu {
//   [k: string]: (props: PropsWithChildren<any>) => JSX.Element;
// }
// const routes = [...GENERAL_MENU, ...SECOND_ROUTES].reduce((acc: IAccMenu, item: IGeneralMenu) => {
//   acc[item.link] = item.component;
//   return acc;
// }, {});

//export default routes;
