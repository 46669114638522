import ScreenPreferences from '../../common/preferences/ScreenPreferences';

const ScreenTotalLookPrefs = () => {
    return (
        <ScreenPreferences
            pageTitle={
                <>
                    Что именно вас
                    <br />
                    интересует на&nbsp;фото?
                </>
            }
            pageSubtitle="Выберите одну или несколько категорий на фото,  в которых необходимо осуществить поиск. Если хотите выбрать все категории — нажмите “выбрать всё”."
        />
    );
};

export default ScreenTotalLookPrefs;
