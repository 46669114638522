import { ReactComponent as FacadeSimilarSvg } from '../../../assets/svg/facade-similar.svg';
import classes from './ScreenSimilar.module.scss';
import { ReactComponent as FacadeSimilarDesktopSvg } from '../../../assets/svg/facade-similar-desktop.svg';
import ScreenFileUpload from '../common/upload-file/ScreenFileUpload';

// todo - create common component for file upload
const ScreenSimilar = () => {
    const pageTitle = (
        <>
            Загрузите фото
            <br />
            и&nbsp;узнайте, где купить
            <br />
            похожие вещи
        </>
    );

    return <ScreenFileUpload pageTitle={pageTitle} css={classes} facadeSvg={<FacadeSimilarSvg />} facadeDesktopSvg={<FacadeSimilarDesktopSvg />} />;
    // return (
    //     <section className={classes[prefix]}>
    //         <div className={prefix + '__gradients'}>
    //             <div className={prefix + '__red-wrapper'}>
    //                 <div className={prefix + '__red'}></div>
    //             </div>
    //             <div className={prefix + '__red2-wrapper'}>
    //                 <div className={prefix + '__red2'}></div>
    //             </div>
    //             <div className={prefix + '__blue-wrapper'}>
    //                 <div className={prefix + '__blue'}></div>
    //             </div>
    //         </div>

    //         <div className={classes[prefix + '__overlay']}></div>
    //         <div className={classes[prefix + '__content']}>
    //             <h2 className={prefix + '__page-title'}>
    //                 Загрузите фото
    //                 <br />
    //                 и&nbsp;узнайте, где купить
    //                 <br />
    //                 похожие вещи
    //             </h2>
    //             <div className={classes[prefix + '__facade-image']}>
    //                 <FacadeSimilarSvg />
    //             </div>
    //             {!fileValue ? (
    //                 <input
    //                     id="file"
    //                     className={classes['custom-file-input']}
    //                     aria-label="File browser example"
    //                     type="file"
    //                     ref={fileInput}
    //                     onChange={() => setFileValue(fileInput?.current?.files[0])}
    //                 />
    //             ) : (
    //                 <div className={classes[prefix + '__file-value']}>
    //                     <div className={classes[prefix + '__file-image']} style={{ backgroundImage: `url(${URL.createObjectURL(fileValue)})` }}></div>
    //                     <span className={classes[prefix + '__file-value-text']}>{fileValue?.name}</span>
    //                     <button type="button" onClick={() => setFileValue(null)}>
    //                         x
    //                     </button>
    //                 </div>
    //             )}
    //             <div>или</div>
    //             <input
    //                 className={
    //                     prefix +
    //                     '__input-text ' +
    //                     classes[prefix + '__input-text-link'] +
    //                     (linkValue ? ' ' + classes[prefix + '__input-text-link--valued'] : '')
    //                 }
    //                 type="text"
    //                 placeholder="Вставить ссылку на фото"
    //                 value={linkValue}
    //                 onChange={(e) => setLinkValue(e.target.value)}
    //             />
    //             <div>
    //                 <ErrorPresenter
    //                     text="Вставьте ссылку на конкретное изображение"
    //                     tooltipText="Сделайте длинный тап по изображению и выберите действие “копировать изображение” или “скопировать”."
    //                 ></ErrorPresenter>
    //             </div>
    //             <button className={classes[prefix + '__button-find']} type="button" disabled={!(linkValue || fileValue)} onClick={() => searchByImage()}>
    //                 Найти
    //             </button>
    //         </div>
    //     </section>
    // );
};

export default ScreenSimilar;
