import '../src/styles/fonts.scss';
import '../src/styles/main.scss';
import '../src/styles/buttons.scss';
import '../src/styles/gradients.scss';
import '../src/styles/layout.scss';
import { Route, NavLink, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { MAIN_MENU } from './routes';
import { useState } from 'react';
import ScreenHome from './components/screens/home/ScreenHome';
import ScreenSimilarResults from './components/screens/similar/results/ScreenSimilarResults';
import ProductPage from './components/ui/product-page/ProductPage';
import ScreenBestCombos from './components/screens/common/best-combos/ScreenBestCombos';
import ButtonBack from './components/ui/button-back/ButtonBack';
import classes from './App.module.scss';
import { prefix } from './js/constants';
import ScreenRecommendedPrefs from './components/screens/recommended/preferences/ScreenRecommendedPrefs';
import ScreenRecommendedQues from './components/screens/recommended/questions/ScreenRecommendedQues';
import ScreenRecommendedLikes from './components/screens/recommended/likes/ScreenRecommendedLikes';
import ScreenLookSetup from './components/screens/popular/look-setup/ScreenLookSetup';
import ScreenRecommendedResults from './components/screens/recommended/results/ScreenRecommendedResults';
import ScreenTotalLookQues from './components/screens/total-look/questions/ScreenTotalLookQues';
import ScreenTotalLookPrefs from './components/screens/total-look/preferences/ScreenTotalLookPrefs';
import ScreenSimilarQues from './components/screens/similar/questions/ScreenSimilarQues';
import ScreenSimilarPrefs from './components/screens/similar/preferences/ScreenSimilarPrefs';
import ScreenTotalLookResults from './components/screens/total-look/results/ScreenTotalLookResults';

function App() {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const history = useHistory();
    const search = useLocation().search;
    const category = new URLSearchParams(search).get('category') || undefined; //todo

    let isHome = history.location.pathname === '/';
    const line = <hr className={'garderobo__line ' + classes[prefix + '__line']} />;

    const header = (backBtn: boolean, titleMobile = 'Назад') => {
        return (
            <div className="garderobo__mobile-header">
                {backBtn ? (
                    <ButtonBack titleMobile={titleMobile} />
                ) : (
                    <NavLink to="/">
                        <div className="garderobo__logo garderobo__mobile-header__logo"></div>
                    </NavLink>
                )}
                <button className="garderobo_header-btn-burger" type="button" onClick={() => setIsMenuOpened(true)}></button>
            </div>
        );
    };

    return (
        <div className="garderobo">
            <div className={'garderobo__left-panel garderobo__left-panel' + (isMenuOpened ? '--opened' : '')}>
                <header className="garderobo__header">
                    <button className="garderobo__header-btn-close" type="button" onClick={() => setIsMenuOpened(false)}></button>
                    <div className="garderobo__logo-wrapper">
                        <NavLink to="/" onClick={() => setIsMenuOpened(false)}>
                            <div className="garderobo__logo"></div>
                        </NavLink>
                    </div>
                    {line}

                    <nav className="garderobo__menu">
                        <ul className={'garderobo__menu-list' + (isHome ? ' garderobo__menu-list--home' : '')}>
                            {MAIN_MENU.map(({ title, link }, index) => (
                                <li className="garderobo__menu-item" key={index}>
                                    <NavLink
                                        className="garderobo__menu-item-link"
                                        activeClassName="garderobo__menu-item-link--active"
                                        to={link}
                                        onClick={() => setIsMenuOpened(false)}
                                    >
                                        {title}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </header>

                <footer className="garderobo__footer">
                    <div className="garderobo__menu-social">
                        <ul className="garderobo__menu-social-list">
                            <li className="garderobo__menu-social-item">
                                <a className="garderobo__menu-social-item-link" href="#">
                                    Facebook
                                </a>
                            </li>
                            <li className="garderobo__menu-social-item">
                                <a className="garderobo__menu-social-item-link" href="#">
                                    Instagram
                                </a>
                            </li>
                            <li className="garderobo__menu-social-item">
                                <a className="garderobo__menu-social-item-link" href="#">
                                    Tiktok
                                </a>
                            </li>
                        </ul>
                    </div>
                    {line}
                    <div className="garderobo__copyright">
                        GardeRobo для бизнеса
                        <div className="garderobo__copyright-domain">&copy;2021 GardeRobo.ai</div>
                    </div>
                </footer>
            </div>
            <main className="garderobo__main">
                <Switch>
                    <Route path="/" exact>
                        {header(false)}
                        <ScreenHome />
                    </Route>

                    {/* similar */}
                    <Route path="/similar/preferences" exact>
                        {header(true)}
                        <ScreenSimilarPrefs />
                    </Route>
                    <Route path="/similar/questions" exact>
                        {header(true)}
                        <ScreenSimilarQues />
                    </Route>
                    <Route path="/similar/results" exact>
                        {header(true)}
                        <ScreenSimilarResults />
                    </Route>
                    <Route path="/similar/results" exact>
                        <Redirect to="/similar/results/"></Redirect>
                    </Route>
                    <Route path="/similar/best-combos" exact>
                        {header(true)}
                        <ScreenBestCombos pageTitle="Готовый образ для тебя" urlPath="similar" />
                    </Route>

                    {/* total-look */}
                    <Route path="/total-look/questions" exact>
                        {header(true)}
                        <ScreenTotalLookQues />
                    </Route>
                    <Route path="/total-look/preferences" exact>
                        {header(true)}
                        <ScreenTotalLookPrefs />
                    </Route>
                    <Route path="/total-look/results" exact>
                        {header(true)}
                        <ScreenTotalLookResults />
                    </Route>

                    {/* recommended */}
                    <Route path="/recommended/preferences" exact>
                        {header(true)}
                        <ScreenRecommendedPrefs />
                    </Route>
                    <Route path="/recommended/questions" exact>
                        {header(true)}
                        <ScreenRecommendedQues />
                    </Route>
                    <Route path="/recommended/likes" exact>
                        {header(true, category)}
                        <ScreenRecommendedLikes />
                    </Route>
                    <Route path="/recommended/results" exact>
                        {header(true, category)}
                        <ScreenRecommendedResults />
                    </Route>

                    {/* popular */}
                    <Route path="/popular/:lookId" exact>
                        {header(true)}
                        <ScreenLookSetup />
                    </Route>
                    {/* add child routes to routes.tsx */}

                    {MAIN_MENU.map(({ link, component }, index) => (
                        <Route path={link} exact key={index}>
                            <>
                                {header(false)}
                                {component(null)}
                            </>
                        </Route>
                    ))}
                    {MAIN_MENU.map(({ name }, index) => (
                        <Route path={`/${name}/products/:productId`} exact key={index}>
                            {header(true)}
                            <ProductPage />
                        </Route>
                    ))}

                    <Redirect to="/" />
                </Switch>
            </main>
        </div>
    );
}

export default App;
