export const productsStub = [
    {
        id: 0,
        brand: 'Saint Laurent',
        price: 457990,
        pic: 'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458464_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/saint-laurent--item-17267449.aspx?storeid=9541',
        name: 'ysl dress',
        sex: 0,
        pics: [
            'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458426_1000.jpg',
            'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458463_1000.jpg',
            'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458441_1000.jpg',
            'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35458443_1000.jpg',
            'https://cdn-images.farfetch-contents.com/17/26/74/49/17267449_35456810_1000.jpg',
        ],
        sizes: ['S', 'M'],
    },
    {
        id: 1,
        brand: 'Dolce & Gabbana',
        price: 350789,
        pic: 'https://cdn-images.farfetch-contents.com/17/05/54/70/17055470_35339915_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/dolce-gabbana--item-17055470.aspx?storeid=9541',
        name: 'dolce coat',
        sex: 0,
    },
    {
        id: 2,
        brand: 'Burberry',
        price: 120000,
        pic: 'https://cdn-images.farfetch-contents.com/17/26/00/86/17260086_35979407_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/burberry--item-17260086.aspx?storeid=9462',
        name: 'burberry sweater',
        sex: 0,
    },
    {
        id: 3,
        brand: 'Gucci',
        price: 267800,
        pic: 'https://cdn-images.farfetch-contents.com/16/78/29/33/16782933_33871955_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-eschatology-item-16782933.aspx?storeid=12347',
        name: 'пальто Eschatology',
        sex: 0,
    },
    {
        id: 4,
        brand: 'Gucci',
        price: 88000,
        pic: 'https://cdn-images.farfetch-contents.com/16/90/25/48/16902548_34027108_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-gg-item-16902548.aspx?storeid=12347',
        name: 'жаккардовое платье с узором GG',
        sex: 0,
    },

    {
        id: 5,
        brand: 'Gucci',
        price: 457990,
        pic: 'https://cdn-images.farfetch-contents.com/12/96/48/25/12964825_21889352_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-gg-marmont-item-12964825.aspx?storeid=12826',
        name: 'ysl dress',
        sex: 0,
    },
    {
        id: 6,
        brand: 'Gucci',
        price: 350789,
        pic: 'https://cdn-images.farfetch-contents.com/12/96/45/41/12964541_13604746_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/dolce-gabbana--item-17055470.aspx?storeid=9541',
        name: 'dolce coat',
        sex: 0,
    },
    {
        id: 7,
        brand: 'Gucci',
        price: 120000,
        pic: 'https://cdn-images.farfetch-contents.com/14/02/43/25/14024325_18222627_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/burberry--item-17260086.aspx?storeid=9462',
        name: 'burberry sweater',
        sex: 0,
    },
    {
        id: 8,
        brand: 'Gucci',
        price: 267800,
        pic: 'https://cdn-images.farfetch-contents.com/16/78/17/56/16781756_34997928_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-eschatology-item-16782933.aspx?storeid=12347',
        name: 'пальто Eschatology',
        sex: 0,
    },
    {
        id: 9,
        brand: 'Gucci',
        price: 88000,
        pic: 'https://cdn-images.farfetch-contents.com/17/01/51/48/17015148_34153286_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-gg-item-16902548.aspx?storeid=12347',
        name: 'жаккардовое платье с узором GG',
        sex: 0,
    },
    {
        id: 10,
        brand: 'Gucci',
        price: 267800,
        pic: 'https://cdn-images.farfetch-contents.com/16/67/13/84/16671384_32640981_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-eschatology-item-16782933.aspx?storeid=12347',
        name: 'пальто Eschatology',
        sex: 1,
    },
    {
        id: 11,
        brand: 'Gucci',
        price: 88000,
        pic: 'https://cdn-images.farfetch-contents.com/16/65/13/15/16651315_32550105_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-gg-item-16902548.aspx?storeid=12347',
        name: 'жаккардовое платье с узором GG',
        sex: 1,
    },
    {
        id: 12,
        brand: 'Gucci',
        price: 88000,
        pic: 'https://cdn-images.farfetch-contents.com/14/02/41/63/14024163_18193863_1000.jpg',
        link: 'https://www.farfetch.com/ru/shopping/women/gucci-gg-item-16902548.aspx?storeid=12347',
        name: 'жаккардовое платье с узором GG',
        sex: 1,
    },
];
