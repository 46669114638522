import { useState } from 'react';
import { LStorage, prefix } from '../../../js/constants';
import IProduct from '../../../models/product';
import { productsStub } from '../../../products_remove';
import ProductCard from '../../ui/product-card/ProductCard';
import ScreenPopularContent from '../popular/popular-content/ScreenPopularContent';
import css from './ScreenFavorites.module.scss';

const ScreenFavorites = () => {
    const likedProductIdsStorage = localStorage.getItem(LStorage.likedProductIds);
    let likedProductIds = likedProductIdsStorage ? JSON.parse(likedProductIdsStorage) : [];
    const [products, setProducts] = useState<Array<IProduct>>([...productsStub].filter((product) => likedProductIds.includes(product.id)));

    const onLike = (productId: number) => {
        const newProducts = products.filter((product) => product.id !== productId);
        setProducts(newProducts);
        console.log(newProducts);
    };

    return (
        <section className={prefix + '__layout ' + css[prefix]}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <header className={css[prefix + '__header']}>
                    <h2 className={prefix + '__page-title-left ' + css[prefix + '__page-title-left']}>Избранное</h2>
                    <h4 className={prefix + '__subtitle'}>Пояснение к разделу</h4>
                </header>

                {!products?.length ? (
                    <div className={prefix + '__center-box'}>Здесь пока ничего нет</div>
                ) : (
                    <ScreenPopularContent>
                        <div className={css[prefix + '__products']}>
                            {products.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    product={product}
                                    hasLike={true}
                                    onLike={onLike}
                                    urlPath="favorites"
                                    customCardCss={css[prefix + '__product-card']}
                                    customPicCss={css[prefix + '__product-card-pic']}
                                />
                            ))}
                        </div>
                    </ScreenPopularContent>
                )}
            </div>
        </section>
    );
};

export default ScreenFavorites;
